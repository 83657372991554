import React, { Component } from 'react';
import { navigate } from "gatsby"
import queryString from 'query-string';
import fetch from 'isomorphic-fetch';
import loadable from '@loadable/component'

export default function Previewable(WrappedComponent, postType){

  const queries = typeof window !== 'undefined' ? queryString.parse(location.search) : null;
  const setPreviewMode = queries && queries.prev && queries.prev === 'true' ? true : false

  if (setPreviewMode) {

    return class extends Component{
      state = {
        previewMode: false,
        fetchingData: false,
        hasPreview: false,
        error:false
      }

      async loadDataCheck() {
        const { location, data } = this.props;
        const queries = typeof window !== 'undefined' ? queryString.parse(location.search) : null;
        const setPreviewMode = queries && queries.prev && queries.prev === 'true' ? true : false

        if (setPreviewMode) {
          const { wordpressPost } = data
          const queries = queryString.parse(location.search);
          const postTypeSelect = queries.type ? queries.type : postType
          const postTypes = postTypeSelect && postTypeSelect.endsWith('s') ? postTypeSelect : `${postTypeSelect}s`

          const previewId = queries.id ? queries.id : wordpressPost.wordpress_id
          if (previewId) {
            this.setState({ fetchingData: true, hasPreview: true, previewMode: true });
            const functionsStringUrl = `${process.env.GATSBY_WP_ADDRESS}/wp-json/wp/v2/${postTypes}/${previewId}?_embed`
            fetch(functionsStringUrl)
            .then(response => response.json())
            .then( data => {
              const thisPageUrl = data.link.replace(process.env.GATSBY_WP_ADDRESS, '')
               this.setState({ fetchingData: false, newData: data, redirectUrl:thisPageUrl });
            })
            .catch(error => {
              //console.error('submitForm Error', error)
              this.setState({ error: 'There is no page by that ID', fetchingData: false});
            });
          }
        }
      }

      componentDidMount() {
        this.loadDataCheck();
      }

      closePreview = () => {
        this.setState({ hasPreview: false, previewMode: false })
        navigate(this.state.redirectUrl)
      }

      render() {
        const { data, location } = this.props;
        const { fetchingData, hasPreview, newData = {}, error, previewMode } = this.state;

        // Not previewing return wrapped component as is
        if (!previewMode) return <WrappedComponent {...this.props} />;

        const { _embedded = null } = newData;

        // Show error UI
        if (error) return <p>{error}</p>;
        // Show fetching UI
        if (fetchingData) {
          loadable(import(`./Previewable.scss`))
          return (
            <div className="fetching-preview"><div className="container"><span>Fetching preview</span><button className="close" title="Stop preview" onClick={() => this.closePreview()} /><div className="loading"/></div></div>
          )
        }
        // Show preview data
        if (hasPreview) {
          // Check for ACF
          const hasAcf = newData.acf && newData.acf.layout;
          // Generate preview data consisting of new and existing data
          const previewData = {
            ...data,
            ['wordpressPost']: {
              ...data['wordpressPost'],
              title: newData.title ? newData.title.rendered : null,
              content: newData.content ? newData.content.rendered : null,
              author: _embedded && _embedded.author ? _embedded.author[0] : null,
              categories: _embedded && _embedded.categories && _embedded.categories.length > 0 ? _embedded.categories[0] : null,
              tags: _embedded && _embedded.tags && _embedded.tags.length > 0 ? _embedded.tags : null,
              featuredImage: _embedded && _embedded['wp:featuredmedia'] && _embedded['wp:featuredmedia'][0].source_url ||  null,
              yoast: {
                metaTitle: 'Preview'
              },
              acf: {
                ...newData.acf,
                layout: hasAcf ? newData.acf.layout.map((item, index) => ({ ...item, __typename: `WordPressAcf_${item.acf_fc_layout}`, id: index })) : []
              }
            }
          };
          // Return wrapped component with new preview data values overwriting existing
          return (
            <>
              <div className="preview-notice"><button onClick={() => this.closePreview()}>Exit preview mode.</button></div>
              <WrappedComponent {...this.props} data={previewData} />
            </>
          )
        }

        return null;
      }
    }

  }else{
    return class extends Component{
      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  }

}
